import { getField, updateField } from 'vuex-map-fields'
import uap from 'ua-parser-js'
import { uri } from '~/api/uri'
import { isServer } from '@/mixins/helpers'

export const initState = {
  appVersionId: null,
  loadingEnable: true,
  loadingManual: false,
  device: null,
  tableLayout: null,
  errors: [],
  markets: []
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  nuxtServerInit ({ commit, dispatch, rootState, config }, { $gates }) {
    commit('setAppVersionId', this.$config.appVersionId)
    const user = rootState.auth && rootState.auth.user
    if (user) {
      $gates.setRoles(user.roles)
      // $gates.setPermissions(user.permissions)
      // get locked items list
      dispatch('loadMarkets')
    }
  },
  setLoadingEnable ({ commit }, value) {
    commit('setLoadingEnable', value)
  },
  setLoadingManual ({ commit }, value) {
    commit('setLoadingManual', value)
  },
  setError ({ commit }, value) {
    commit('setError', value)
  },
  flushErrors ({ commit }) {
    commit('flushErrors')
  },
  getDeviceInfo ({ commit }) {
    const ua = uap(window?.navigator?.userAgent || navigator.userAgent || '')
    commit('setDeviceInfo', ua)
  },
  getTableLayout ({ commit, state }) {
    // get
    let lt
    if (!isServer) {
      lt = localStorage.getItem('TableLayout')
    }
    if (!lt) {
      if (
        state.device &&
        (
          state.device.type === 'mobile' ||
          state.device.type === 'tablet'
        )
      ) {
        lt = 'card'
      } else {
        lt = 'table'
      }
    }
    commit('setTableLayout', lt)
  },
  setTableLayout ({ commit, state }, lt) {
    // get
    if (!lt) {
      if (
        state.device &&
        (
          state.device.type === 'mobile' ||
          state.device.type === 'tablet'
        )
      ) {
        lt = 'card'
      } else {
        lt = 'table'
      }
    }
    commit('setTableLayout', lt)
  },
  async loadMarkets ({ commit }) {
    const res = await this.$axios(uri.markets)
    if (res.data && res.data.data) {
      commit('setMarkets', res.data.data)
    }
  }
}
export const mutations = {
  updateField,
  setLoadingEnable (state, value) {
    state.loadingEnable = !!value
  },
  setLoadingManual (state, value) {
    state.loadingManual = !!value
  },
  setError (state, error) {
    state.errors.push(error)
  },
  flushErrors (state) {
    state.errors = []
  },
  setMarkets (state, value) {
    state.markets = value
  },
  setAppVersionId (state, value) {
    state.appVersionId = value
  },
  setDeviceInfo (state, value) {
    if (value) {
      state.device = value?.device
      if (typeof state.device.type === 'undefined') {
        state.device.type = 'desktop'
      }
    }
  },
  setTableLayout (state, value) {
    state.tableLayout = value
    // update local storage
    if (!isServer) {
      if (localStorage.getItem('TableLayout') !== value) {
        localStorage.setItem('TableLayout', value)
      }
    }
  }
}
export const getters = {
  getField,
  errors (state) {
    return state.errors
  },
  markets (state) {
    return state.markets
  },
  loadingEnable (state) {
    return state.loadingEnable
  },
  loadingManual (state) {
    return state.loadingManual
  },
  device (state) {
    return state.device
  },
  tableLayout (state) {
    return state.tableLayout
  }
}
